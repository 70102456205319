module.exports = [{
    "projNumber": 1,
    "URLquery": "JasonClerkID",
    "projName": "Jason Clerk ID",
    "projType": "Design and Development",
    "client": "Jason Clerk",
    "mediaPartner": "N/A",
    "creativeAgency": "N/A",
    "aor": "N/A",
    "timing": "2021",
    "duration": "6 Months",
    "role": "Designer and Developer",
    "splashImage":".././Images/JCID/splash_jcid.svg",
    "splash":"NEED TO ADD PROJECT DETAILS",
    "heading1": "The Backstory",
    "bodyCopy1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "projImages": [{
        "image":"../../samples/1.png",
        "key": 1,
        "caption": "",
      },
      {
        "image":"../../samples/2.png",
        "key": 2,
        "caption": "",
      },
      {
        "image":"../../samples/3.png",
        "key": 3,
        "caption": "",
      },
      {
        "image":"../../samples/4.png",
        "key": 4,
        "caption": "",
      }],
},{
    "projNumber": 2,
    "URLquery": "SugarFlower",
    "projName": "Sugar & Flower",
    "projType": "UX/UI Design, Spec Work",
    "client": "Spec Work",
    "mediaPartner": "N/A",
    "creativeAgency": "N/A",
    "aor": "N/A",
    "timing": "2017",
    "duration": "3 Months",
    "role": "UX/UI Designer",
    "splash":"NEED TO ADD PROJECT DETAILS",
    "heading1": "The Backstory",
    "bodyCopy1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "projImages": [{
        "image":"../../samples/1.png",
        "key": 1,
        "caption": "",
      },
      {
        "image":"../../samples/2.png",
        "key": 2,
        "caption": "",
      },
      {
        "image":"../../samples/3.png",
        "key": 3,
        "caption": "",
      },
      {
        "image":"../../samples/4.png",
        "key": 4,
        "caption": "",
      },
      {
        "image":"../../samples/5.png",
        "key": 5,
        "caption": "",
      },
      {
        "image":"../../samples/6.png",
        "key": 6,
        "caption": "",
      },
      {
        "image":"../../samples/7.png",
        "key": 7,
        "caption": "",
      }],
},{
    "projNumber": 3,
    "URLquery": "Canada150",
    "projName": "Canada 150",
    "projType": "Brand Partnership",
    "client": "Government of Canada",
    "mediaPartner": "Spotify",
    "creativeAgency": "The Tank Media, Red Apple Creative",
    "aor": "Cossette",
    "timing": "2017",
    "duration": "8 Months",
    "role": "Ad Sales Account Director/Product Owner",
    "splashImage":".././Images/Canada150/splash_canada150.svg",
    "splash":"NEED TO ADD PROJECT DETAILS",
    "heading1": "The Backstory",
    "bodyCopy1": "The Canada 150 project was a multi-faceted marketing effort that ran in 2017 to celebrate the 150 years that passed since Confederation. The partnership between the Government of Canada (GoC) and Spotify Canada spanned several months and included an entire ecosystem (offline and online) to capture the interest of Canadians, and drive excitement leading up to July 1st. The project was seminal to my personal development, cementing several skills that are transferable to UX/UI Design.",
    "heading2": "Problem to Solve & Core Considerations",
    "bodyCopy2": "Initially, the project started strictly as a partnership between the GoC and our Ad Sales team, but I quickly realized that there was potential benefits to expanding the partnership beyond my team. Promoting Canadian artists and showcasing the diversity of Canadian talent was an objective shared by the GoC and Spotify’s Consumer Marketing, Editorial and Artist & Label Relations teams. As such, it made perfect sense to create a co-branded solution to meet the shared goal. The problem or question at hand became “How do we create an exciting, and completely innovative promotion (of massive proportions), that included an external partner, and would satisfy/balance the slightly nuanced objectives of all stakeholders involved?",
    "heading3": "Final Solution - The Ecosystem",
    "bodyCopy3": "In a nutshell, we worked as a team and we leveraged data to inform what we created. I expand further on how we did this in the next section, “Path to Solution”. The final solution was a Canada 150 Ecosystem consisting of 3 main components: \n Spotify Brand Campaign (Advertising, PR, Social Media, etc.) Playlist Hub on Spotify (featured custom co-branded content alongside Spotify Editorial content) Musical History Timeline Website (co-branded site)",
    "projImages": [{
        "image":"../../public/Images/Canada150/GoCTimelineSite-1.png",
        "key": 1,
        "caption": "Government of Canada + Spotify Musical Timeline Website",
      },
      {
        "image":"../../Canada150/GoCTimelineSite-2.png",
        "key": 2,
        "caption": "Government of Canada + Spotify Musical Timeline Website",
      },
      {
        "image":"../../Canada150/GoCTimelineSite-3.png",
        "key": 3,
        "caption": "Government of Canada + Spotify Musical Timeline Website",
      },
      {
        "image":"../../Canada150/GoCTimelineSite-4.png",
        "key": 4,
        "caption": "Government of Canada + Spotify Musical Timeline Website",
      },
      {
        "image":"../../Canada150/GoCTimelineSite-5.png",
        "key": 5,
        "caption": "Government of Canada + Spotify Musical Timeline Website",
      },
      {
        "image":"../../../../Canada150/GoCTimelineSite-6.png",
        "key": 6,
        "caption": "Government of Canada + Spotify Musical Timeline Website",
      },
      {
        "image":"../../../../Canada150/GoCTimelineSite-7.png",
        "key": 7,
        "caption": "Government of Canada + Spotify Musical Timeline Website",
      },
      {
        "image":"../../../../Canada150/GoCTimelineSite-8.png",
        "key": 8,
        "caption": "Government of Canada + Spotify Musical Timeline Website",
      },
      {
        "image":"../../../../Canada150/OOH_creative.png",
        "key": 9,
        "caption": "Spotify Consumer Marketing OOH Creative",
      },
      {
        "image":"../../../../Canada150/OOH_creative2.png",
        "key": 10,
        "caption": "Spotify Consumer Marketing OOH Creative",
      },
      {
        "image":"../../../../Canada150/OOH_creative3.png",
        "key": 11,
        "caption": "Spotify Consumer Marketing OOH Creative",
      },
      {
        "image":"../../../../Canada150/Hub.png",
        "key": 12,
        "caption": "Canada150 Playlist Hub on Spotify platform",
      }],
},{
    "projNumber": 4,
    "URLquery": "McDonaldsGCTA",
    "projName": "McDonald's GCTA",
    "projType": "Brand Partnership",
    "client": "McDonald's Canada",
    "mediaPartner": "Spotify",
    "creativeAgency": "Cossette Canada",
    "aor": "OMD Canada",
    "timing": "2016",
    "duration": "2 Months",
    "role": "Ad Sales Account Director/Product Owner",
    "splash":"NEED TO ADD PROJECT DETAILS",
    "heading1": "The Backstory",
    "bodyCopy1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "projImages": [{
        "image":"../../samples/1.png",
        "key": 1,
        "caption": "",
      },
      {
        "image":"../../samples/2.png",
        "key": 2,
        "caption": "",
      },
      {
        "image":"../../samples/3.png",
        "key": 3,
        "caption": "",
      },
      {
        "image":"../../samples/4.png",
        "key": 4,
        "caption": "",
      },
      {
        "image":"../../samples/5.png",
        "key": 5,
        "caption": "",
      },
      {
        "image":"../../samples/6.png",
        "key": 6,
        "caption": "",
      },
      {
        "image":"../../samples/7.png",
        "key": 7,
        "caption": "",
      }],
},
]