import React from 'react';

const Pagination = ({ className }) => {
    return(<svg className={className} width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1" width="34" height="34" rx="17" stroke="#F0D3F7"/>
    <path id="pathA" d="M12.852 21.26H21.124V20.476H19.028H18.852H14.324L18.484 14.332V20.764V20.908V23.5H19.364V12.3H19.028L12.852 21.26Z" fill="#28052A"/>
    <path id="pathB" d="M17.016 17.868C17.6773 17.868 18.2747 17.7507 18.808 17.516C19.352 17.2707 19.784 16.9347 20.104 16.508C20.4347 16.0813 20.6 15.596 20.6 15.052C20.6 14.54 20.4773 14.0653 20.232 13.628C19.9973 13.1907 19.6507 12.8333 19.192 12.556C18.744 12.2787 18.2 12.14 17.56 12.14C16.952 12.14 16.4133 12.268 15.944 12.524C15.4853 12.7693 15.1227 13.1107 14.856 13.548C14.6 13.9853 14.472 14.4973 14.472 15.084H15.32C15.32 14.4653 15.528 13.9533 15.944 13.548C16.36 13.1427 16.904 12.94 17.576 12.94C18.0347 12.94 18.4187 13.0413 18.728 13.244C19.048 13.436 19.288 13.6973 19.448 14.028C19.6187 14.348 19.704 14.7 19.704 15.084C19.704 15.4147 19.6293 15.7187 19.48 15.996C19.3413 16.2627 19.144 16.492 18.888 16.684C18.6427 16.876 18.36 17.0253 18.04 17.132C17.72 17.2387 17.3787 17.292 17.016 17.292V17.868ZM17.496 23.66C18.168 23.66 18.76 23.532 19.272 23.276C19.784 23.02 20.184 22.6627 20.472 22.204C20.7707 21.7453 20.92 21.212 20.92 20.604C20.92 20.0707 20.808 19.6067 20.584 19.212C20.3707 18.8173 20.0773 18.4973 19.704 18.252C19.3307 17.996 18.9093 17.8093 18.44 17.692C17.9813 17.564 17.5067 17.5 17.016 17.5V18.076C17.3787 18.076 17.736 18.1293 18.088 18.236C18.44 18.332 18.76 18.4813 19.048 18.684C19.3467 18.8867 19.5813 19.1427 19.752 19.452C19.9333 19.7507 20.024 20.1027 20.024 20.508C20.024 20.9667 19.9173 21.372 19.704 21.724C19.5013 22.0653 19.208 22.3373 18.824 22.54C18.4507 22.732 18.008 22.828 17.496 22.828C16.9627 22.828 16.4987 22.732 16.104 22.54C15.7093 22.3373 15.4053 22.0653 15.192 21.724C14.9893 21.372 14.888 20.9773 14.888 20.54H13.992C13.992 20.9773 14.072 21.388 14.232 21.772C14.392 22.1453 14.6213 22.476 14.92 22.764C15.2293 23.0413 15.5973 23.26 16.024 23.42C16.4613 23.58 16.952 23.66 17.496 23.66Z" fill="#28052A"/>
    <path id="pathC" d="M13.352 23.5H21V22.668H15.192L19.272 18.46C19.6987 18.0227 20.056 17.5373 20.344 17.004C20.6427 16.4707 20.792 15.9 20.792 15.292C20.792 14.9507 20.728 14.5987 20.6 14.236C20.472 13.8627 20.2693 13.5213 19.992 13.212C19.7253 12.892 19.3787 12.6307 18.952 12.428C18.536 12.2253 18.0347 12.124 17.448 12.124C16.7227 12.124 16.0933 12.2947 15.56 12.636C15.0373 12.9667 14.632 13.436 14.344 14.044C14.0667 14.6413 13.928 15.34 13.928 16.14H14.808C14.808 15.5 14.9147 14.94 15.128 14.46C15.3413 13.98 15.6453 13.612 16.04 13.356C16.4347 13.0893 16.904 12.956 17.448 12.956C17.864 12.956 18.2213 13.0307 18.52 13.18C18.8293 13.3187 19.0853 13.5053 19.288 13.74C19.4907 13.964 19.64 14.2147 19.736 14.492C19.832 14.7693 19.88 15.0467 19.88 15.324C19.88 15.676 19.8213 16.0067 19.704 16.316C19.5973 16.6147 19.4427 16.8973 19.24 17.164C19.048 17.4307 18.8293 17.7027 18.584 17.98L13.352 23.5Z" fill="#28052A"/>
    <path id="pathD" d="M15.376 13.916L17.952 13.244V23.5H18.832V12.172L15.376 13.004V13.916Z" fill="#28052A"/>
    </svg>
    
    
    
)
}
export default Pagination;