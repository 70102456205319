import React from 'react';

const EmailIcon = ({ className }) => {
    return(<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_710_2254)">
    <path d="M3 5V4.5C2.72386 4.5 2.5 4.72386 2.5 5H3ZM21 5H21.5C21.5 4.72386 21.2761 4.5 21 4.5V5ZM3 5.5H21V4.5H3V5.5ZM20.5 5V17H21.5V5H20.5ZM19 18.5H5V19.5H19V18.5ZM3.5 17V5H2.5V17H3.5ZM5 18.5C4.17157 18.5 3.5 17.8284 3.5 17H2.5C2.5 18.3807 3.61929 19.5 5 19.5V18.5ZM20.5 17C20.5 17.8284 19.8284 18.5 19 18.5V19.5C20.3807 19.5 21.5 18.3807 21.5 17H20.5Z" fill="#F0D3F7"/>
    <path d="M3 5L12 14L21 5" stroke="#F0D3F7" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_710_2254">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
)
}
export default EmailIcon;