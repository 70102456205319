import React from "react";


function Footer() {
    return (
        <div className="footer">
            <div>Site design and development by Meera Shah.</div> 
            <div>Copyright © 2022 Meera Shah.</div>
        </div>
    );
}

export default Footer;